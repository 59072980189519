import { GRIND_MLO_DATA_URL } from "../util/config.js";
import { app, brandApp } from "../firebase";

export const getUrlPath = () => {
  let url = window.location.href;
  if (url.includes("www.")) {
    url = url.replace("www.", "");
  }

  const path = url.split("/");

  // remove the #
  let pathName = path[path.length - 1];
  // console.log("pathName", pathName);

  if (pathName.includes("#")) {
    pathName = pathName.split("#")[0];
  }
  if (pathName.includes("?")) {
    pathName = pathName.split("?")[0];
  }

  // urls to redirect to https://bluebirdhl.com = 'jwestover', 'ascott',  'gporter', 'tporter', jastorquia
  if (url.includes("onetrusthomeloans.com")) {
    if (
      pathName === "jwestover" ||
      pathName === "ascott" ||
      pathName === "gporter" ||
      pathName === "tporter" ||
      pathName === "jastorquia"
    ) {
      window.location.href = `https://bluebirdhl.com/contact/${pathName}`;
    }

    if (pathName === "bmontgomery" || pathName === "lransom") {
      window.location.href = `https://partnerhl.com/contact/${pathName}`;
    }

    if (pathName === "denniso") {
      window.location.href = `https://savihl.com/contact/${pathName}`;
    }
  }

  console.log("pathName", pathName);
  console.log("path", path);

  return pathName;
};

export const getUrl = () => {
  let url = window.location.href;

  // trim the www. if it exists
  if (url.includes("www.")) {
    url = url.replace("www.", "");
  }

  let domainName = url.split("/")[2];
  console.log("domainName", domainName);

  return domainName;
};

export const getMLOData = async (id) => {
  id = id.toLowerCase();
  const response = await fetch(`${GRIND_MLO_DATA_URL}/${id}`)
    .then((response) => response.json())
    .then((data) => {
      // console.log("data", data);
      return data;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
  return response;
};

export const searchFirebase = async (id) => {
  id = id.toLowerCase();
  const db = app.firestore();
  const docRef = db.collection("users").doc(id);
  const doc = await docRef.get();
  if (!doc.exists) {
    console.log("No such document!");
  } else {
    return doc.data();
  }
};

export const getBrand = async (id) => {
  if (!id) return;

  // Normalize the URL
  const normalizeUrl = (url) => {
    let normalized = url
      .toLowerCase()
      .replace(/^https?:\/\//, "") // Remove http:// or https://
      .replace(/\/$/, ""); // Remove trailing slash
    return normalized === "localhost:3000" ? "arborhl.com" : normalized;
  };

  // Process document data
  const processDocData = (doc) => {
    const data = doc.data();
    Object.keys(data).forEach((key) => {
      if (key.startsWith("cp_")) {
        const newKey = key.replace("cp_", "");
        // Only overwrite if cp_ field has a value
        if (data[key] !== "" && data[key] != null) {
          data[newKey] = data[key];
        }
        delete data[key];
      }
    });
    if (data.showOnContPg == false) return null;

    return data;
  };

  // Query Firestore
  const queryFirestore = async (url) => {
    const db = brandApp.firestore();
    const snapshot = await db
      .collection("brands")
      .where("normalized_WebsiteUrl", "==", url)
      .get();

    if (!snapshot.empty) {
      return processDocData(snapshot.docs[0]);
    }
    return null;
  };

  // Try both with and without trailing slash
  const normalizedId = normalizeUrl(id);
  console.log("normalizedId", normalizedId);
  const result =
    (await queryFirestore(normalizedId)) ||
    (await queryFirestore(`${normalizedId}/`));

  if (!result) {
    console.log("No matching documents.");
    return;
  }

  console.log("data", result);
  return result;
};

export function convertToReactNamingConvention(str) {
  // Split the input string by spaces
  const words = str.split(" ");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the capitalized words and remove spaces
  const camelCase = capitalizedWords.join("");

  // Convert the first letter of the camelCase string to uppercase
  const reactNamingConvention =
    camelCase.charAt(0).toUpperCase() + camelCase.slice(1);

  return reactNamingConvention;
}

export const userBrandIsAllowed = (details, customizations, branding) => {
  if (!details || !branding) return false;

  if (details.MLO_Page__c === "False") {
    return false;
  }

  // if customizations.options.allowedBrands object has a brand that is true, then see if the branding.name is in the allowedBrands array
  if (customizations && customizations?.options?.allowedBrands) {
    let allowedBrands = customizations.options.allowedBrands;
    if (allowedBrands[branding.name]) {
      return true;
    }
  }

  // if details.brand__c is not empty, then see if the branding.name is the same
  if (details.Brand__c) {
    if (details.Brand__c === branding.name) {
      return true;
    }
  }
};
