import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const app = firebase.initializeApp({
  apiKey: "AIzaSyCUAlm8IftRZZqhL332dR-kJScoChDZJFA",
  authDomain: "mlo-pages.firebaseapp.com",
  projectId: "mlo-pages",
  storageBucket: "mlo-pages.appspot.com",
  messagingSenderId: "20325402914",
  appId: "1:20325402914:web:38a1017aabca4cdb007d7b",
});

const FlyerGenConfig = {
  apiKey: "AIzaSyDhGIKtKMnSpqZLiv6jOh_vyUg7MzM_xik",
  authDomain: "flyer-generator-f5314.firebaseapp.com",
  projectId: "flyer-generator-f5314",
  storageBucket: "flyer-generator-f5314.appspot.com",
  messagingSenderId: "305803037946",
  appId: "1:305803037946:web:416b8191b69ba2678bcd43",
};

const flyerApp = firebase.initializeApp(FlyerGenConfig, "flyerGenerator");

export const auth = firebase.auth();

export { app, flyerApp as brandApp };
