import React, { useEffect } from "react";
import Button from "../styled-components/Button";
import SpanishBanner from "../dynamic-sections/SpanishBanner";

import { ReactComponent as PhoneIcon } from "../../assets/icons/cell-phone-icon.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/email-icon.svg";

export default function SideBar({ currentUser }) {
  return (
    <div
      style={{
        backgroundImage: `url(${currentUser?.branding?.sideBarBackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className="h-full flex md:block flex-col items-center"
    >
      <div className="xl:relative ">
        <div
          className={
            (classNames = currentUser?.details?.Website_Headshot__c
              ? ""
              : "h-2/5")
          }
        >
          <div>
            <img
              src={currentUser?.branding?.whiteLogoUrl}
              alt="logo"
              className={
                currentUser?.branding?.WebsiteUrl === "savihl.com"
                  ? "w-2/5 mx-auto pt-4" // Smaller logo for savihl.com
                  : "w-2/3 mx-auto pt-6" // Default size for other brands
              }
            />
          </div>

          {currentUser?.customizations?.options?.habloEspBanner && (
            <SpanishBanner
              backgroundColor={currentUser?.branding?.secondaryColor}
            /> // Conditionally render the SpanishBanner with brand color
          )}

          {currentUser?.details?.Website_Headshot__c ? (
            <div className="mt-4 xl:mt-6 flex justify-center">
              <div className="rounded-full overflow-hidden w-40 h-40 xl:w-[270px] xl:h-[270px] bg-transparent">
                <div
                  className="w-full h-full bg-top bg-cover"
                  style={{
                    backgroundImage: `url(${currentUser?.details?.Website_Headshot__c})`,
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="h-12"></div>
          )}
        </div>
        <div className="mb-4">
          <div className="xl:mt-8 xl:hidden">
            <h1 className="lg:text-3xl xl:text-5xl font-bold text-white mt-4">
              {currentUser?.details?.FirstName} {currentUser?.details?.LastName}
            </h1>
            <h1 className="lg:text-3xl xl:text-5xl font-bold text-white mt-1"></h1>
          </div>
          <div className="mt-8 hidden xl:block">
            <h1 className="lg:text-3xl xl:text-4xl font-bold text-white mt-3">
              {currentUser?.details?.FirstName}
            </h1>
            <h1 className="lg:text-3xl xl:text-4xl font-bold text-white mt-1">
              {currentUser?.details?.LastName}
            </h1>
          </div>
          <div className="w-64 mx-auto px-6">
            <h2 className="lg:text-md xl:text-xl font-[500] text-white xl:mt-4">
              {currentUser?.details?.Title}
            </h2>
          </div>
          <div className="text-white lg:text-md xl:text-lg">
            {currentUser?.details?.LoanForce__NMLS_Number__c && (
              <>
                <span>NMLS #</span>
                <span className="text-white">
                  {" "}
                  {currentUser?.details?.LoanForce__NMLS_Number__c}
                </span>
              </>
            )}
          </div>
          <div className="mt-4 xl:mt-10 flex justify-start flex-col items-center md:items-start md:ml-16 xl:ml-20 space-y-4 lg:text-md xl:text-lg">
            <div className="flex items-center">
              {currentUser?.details?.Marketing_Number__c && (
                <>
                  <PhoneIcon
                    className="inline-block mr-4 text-white fill"
                    style={{ width: "20px", height: "25px", fill: "white" }}
                  />
                  <span className="text-white hover:underline">
                    {" "}
                    <a
                      href={`tel:${currentUser?.details?.Marketing_Number__c}`}
                    >
                      {currentUser?.details?.Marketing_Number__c}
                    </a>
                  </span>
                </>
              )}
              {!currentUser?.details?.Marketing_Number__c &&
                currentUser?.details?.Phone && (
                  <>
                    <PhoneIcon
                      className="inline-block mr-4 text-white fill"
                      style={{ width: "20px", height: "25px", fill: "white" }}
                    />
                    <span className="text-white hover:underline">
                      {" "}
                      <a href={`tel:${currentUser?.details?.Phone}`}>
                        {currentUser?.details?.Phone}
                      </a>
                    </span>
                  </>
                )}
            </div>
            <div className="flex items-center">
              <EmailIcon
                className="inline-block mr-4 text-white fill"
                style={{ width: "20px", height: "25px", fill: "white" }}
              />
              {currentUser?.details?.Email && (
                <span className="text-white hover:underline">
                  <a href={`mailto:${currentUser?.details?.Email}`}>Email Me</a>
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="mt-62 flex flex-col space-y-8 justify-start pt-4 items-center h-36">
          {currentUser?.details?.Encompass_Application_URL__c && (
            <div>
              <a
                href={currentUser?.details?.Encompass_Application_URL__c}
                target="_blank"
              >
                <Button
                  color={currentUser?.branding?.primaryButtonColor}
                  text={"Apply Now"}
                />
              </a>
            </div>
          )}
          {currentUser?.details?.GA_Encompass_Application_URL__c && (
            <div>
              <a
                href={currentUser?.details?.GA_Encompass_Application_URL__c}
                target="_blank"
              >
                <Button
                  color={currentUser?.branding?.primaryButtonColor}
                  text={"Apply Now"}
                />
              </a>
            </div>
          )}
          {currentUser?.customizations?.options?.bookingLink ? (
            <div className="pb-8 xl:pb-2">
              <a
                href={currentUser?.customizations?.options?.bookingLink}
                target="_blank"
              >
                <Button
                  id="some-element"
                  color={currentUser?.branding?.secondaryButtonColor}
                  text={"Book an Appoinment"}
                />
              </a>
            </div>
          ) : (
            <div className=""></div>
          )}
        </div>
      </div>
    </div>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
